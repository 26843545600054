let SERVER_URL = "";

if(window.location.href.includes("stage-")){
    SERVER_URL = "https://republic.webarysites.com"
}else{
    SERVER_URL = "https://api.valuflow.webaryco.com"
    //SERVER_URL = "https://localhost:44354"
}
export const GRAPH_API_URL = "https://graph.microsoft.com/v1.0/";

export { SERVER_URL };
export const ENABLE_SNOOZE = true;