import { useContext, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { Button } from "react-bootstrap";
import { File, Trash } from "react-feather";
import Popconfirm from "../../../../components/Popconfirm";
import { DragContext } from "../../../../contexts/DragContext";

const FileItem = ({ item, onDelete, onMove, selectedFilesIds, setSelectedFilesIds }) => {
  const [deletePopup, setDeletePopup] = useState(false);
  const {
    draggingFileId,
    isDragging: isContextDragging,
    setLocalDragState,
  } = useContext(DragContext);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "file",
    item: { ...item },
    end: (file, monitor) => {
      const folder = monitor.getDropResult();
      if (file && folder) {
        onMove(file, folder);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (
      isDragging === isContextDragging ||
      (draggingFileId && draggingFileId !== item.id)
    ) {
      return;
    }
    if (isDragging) {
      setLocalDragState(item.id, true);
    } else {
      setLocalDragState("", false);
    }
  }, [isContextDragging, isDragging, item.id]);

  const handleFileDelete = () => {
    onDelete();
    setDeletePopup(false);
  };

  const selected = selectedFilesIds.includes(item.id);

  return (
    <div className="ps-1 file-item">
      <Popconfirm
        show={deletePopup}
        onCancel={() => setDeletePopup(false)}
        title="Delete this file?"
        onOk={handleFileDelete}
      />
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          className="ms-3"
          checked={selected}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedFilesIds([...selectedFilesIds, item.id]);
            } else {
              setSelectedFilesIds(selectedFilesIds.filter(id => id !== item.id));
            }
          }}
        />


        <Button
          ref={drag}
          style={{ opacity: isDragging ? 0.4 : 1 }}
          variant="link"
          className="text-break text-dark"
          size="sm"
          onClick={() => window.open(item.webUrl)}
        >
          <File size={18} />
          {item.name}
        </Button>
        <Button variant="link" size="sm" onClick={() => setDeletePopup(true)}>
          <Trash size={18} className="text-danger" />
        </Button>
      </div>
      
    </div>
  );
};

export default FileItem;
