import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import { Button, ButtonGroup, Card, Form } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import { Edit3, Trash2 } from "react-feather";
import LoadingWrapper from "../../components/LoadingWrapper";
import Popconfirm from "../../components/Popconfirm";
import { AuthContext } from "../../contexts/JWTContext";
import useAppSelector from "../../hooks/useAppSelector";
import {
  createTicketNote,
  getTicketNotes,
  updateTicketNote,
  deleteTicketNote,
} from "../../services/ticketNote";
import {
  createTicketStepNote,
  getTicketStepNotes,
  updateTicketStepNote,
  deleteTicketStepNote,
} from "../../services/ticketStepNote";
import { apiErrorHandler, getFormattedDateTimeStrV3 } from "../../utils";
import { USER_ROLES } from "../../constants";
import "./notesAndMemos.css";
import {
  createInspectionNote,
  getInspectionNotes,
  updateInspectionNote,
  deleteInspectionNote
} from "../../services/inspectionNote";
import { createTicketStatusNote, deleteTicketStatusNote, getTicketStatusNotes, updateTicketStatusNote } from "../../services/ticketStatusNote";
import useScreenSize from "../../hooks/useScreenSize";

const NotesAndMemos = ({ ticketId, ticketStepId, ticketStatusTypeId, inspectionId, inspectionApi, isMemo = false, isTicketStatusMemo = false }) => {
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  const [noteToDelete, setNoteToDelete] = useState(0);
  const [noteToUpdate, setNoteToUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useScreenSize();
  const savedRef = useRef(true);

  const api = useMemo(() => {
    // TODO: remove `inspectionApi` and its unneeded logics
    // if (inspectionId) {
    //   return inspectionApi;
    // }

    // console.log(inspectionId);
    if (inspectionId) {
      return {
        create: (note) => createInspectionNote(inspectionId, note),
        get: () => getInspectionNotes(inspectionId),
        update: updateInspectionNote,
        delete: deleteInspectionNote,
      };
    }

    if (!ticketId || (isMemo && !ticketStepId)) {
      return;
    }

    if (isMemo) {
      return {
        create: (note) => createTicketStepNote(ticketStepId, note),
        get: () => getTicketStepNotes(ticketStepId),
        update: updateTicketStepNote,
        delete: deleteTicketStepNote,
      };
    }
    else if (isTicketStatusMemo) {
      return {
        create: (note) => createTicketStatusNote(ticketId, ticketStatusTypeId, note),
        get: () => getTicketStatusNotes(ticketId, ticketStatusTypeId),
        update: updateTicketStatusNote,
        delete: deleteTicketStatusNote
      }
    }
    return {
      create: (note) => createTicketNote(ticketId, note),
      get: () => getTicketNotes(ticketId),
      update: updateTicketNote,
      delete: deleteTicketNote,
    };
  }, [isMemo, ticketStepId, ticketId, inspectionId, inspectionApi]);

  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const users = useAppSelector((state) => state.appData.users);
  const { user } = useContext(AuthContext);
  const isAdmin = user?.roles.includes(USER_ROLES.ADMIN);
  const userId = users.find(item => item.username === user.userName)?.id;

  useEffect(() => {
    /** @param {BeforeUnloadEvent} e */
    function onUnload(e) {
      if (!savedRef.current) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    }
  }, [])

  useEffect(() => {
    if (!api) {
      return;
    }
    setLoading(true);
    api
      .get(ticketId)
      .then(setNotes)
      .finally(() => {
        setLoading(false);
      });
  }, [api]);

  useEffect(() => {
    if (notes && notes.length > 0) {
      const lastItem = notes[notes.length - 1];
      const container = document.getElementsByClassName('notes-or-memos')[0];
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
      // causing a lot of scrolls and unexpected behaviour
      //const node = document.getElementById(`note-${lastItem.id}`);
      //const scrollNode = ReactDOM.findDOMNode(node);
      //scrollNode?.scrollIntoView();
    }
  }, [notes]);

  const handleNoteSave = async (note, id) => {
    setLoading(true);
    try {
      if (id) {
        await api.update(id, note);
        setNoteToUpdate(0);
        savedRef.current = true;
      } else {
        const response = await api.create(note);
        setNotes([...notes, response]);
        setNote("");
        savedRef.current = true;
      }
    } catch (err) {
      apiErrorHandler(err);
    }
    setLoading(false);
  };

  const handleNoteDelete = () => {
    setLoading(true);
    const deleteId = noteToDelete;
    setNoteToDelete(0);
    api
      .delete(deleteId)
      .then(() => {
        setNotes(notes.filter((item) => item.id !== deleteId));
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  const onNoteChange = (e) => {
    savedRef.current = false;
    setNote(e.target.value);
  }

  return (
    <LoadingWrapper loading={loading} className={`notes-or-memos-container`}>
      <Prompt
        when={!savedRef.current}
        message="Changes you made may not be saved. Leave?"
      />
      <Popconfirm
        title="Are you sure?"
        show={!!noteToDelete}
        onOk={handleNoteDelete}
        onCancel={() => setNoteToDelete(0)}
      />
      {inspectionApi ? (
        <Form.Label>
          <b>Inspector Notes</b>
        </Form.Label>
      ) : isMemo ? (
        <b style={{ fontSize: "18px" }}>Memos</b>
      ) : isTicketStatusMemo ? (
        <Form.Label>
          <b>Status Notes</b>
        </Form.Label>) : (
        <Form.Label>
          <b>General Notes</b>
        </Form.Label>
      )}
      <div className="notes-or-memos">
        {notes.map((item, index) => (
          <Card key={item.id} id={`note-${item.id}`} className="note-or-memo-card mb-0">
            <Card.Body className="note-or-memo-card-body p-2">
              {item.id === noteToUpdate ? (
                <Form.Group
                  style={{ flex: 1 }}
                  className="d-flex flex-column note-or-memo-edit-group"
                >
                  <Form.Control
                    className="h-100 mb-2"
                    as="textarea"
                    rows="9"
                    name="formNewGeneralNotes"
                    style={{ width: "99%", margin: "auto", height: 192 }}
                    value={item.note}
                    onChange={(e) => {
                      notes[index] = {
                        ...notes[index],
                        note: e.target.value,
                      };
                      setNotes([...notes]);
                      savedRef.current = false;
                    }}
                  />
                  <ButtonGroup className="note-or-memo-edit-actions-container">
                    <Button
                      size="sm"
                      variant="danger"
                      className="note-or-memo-edit-actions-button me-1"
                      onClick={() => setNoteToUpdate(0)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      className="note-or-memo-edit-actions-button"
                      onClick={() => handleNoteSave(item.note, item.id)}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </Form.Group>
              ) : (
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {item.userId && <span className="memo-created-by-user-name">
                        {usersMap[item.userId]?.firstName} {usersMap[item.userId]?.lastName}
                      </span>}
                      {(item.updated || item.created) && <span>
                        @{" "}
                        {getFormattedDateTimeStrV3(item.updated || item.created)}
                      </span>}
                    </div>
                    {(usersMap[item.userId]?.id === userId || isAdmin) && (
                      <div className="d-flex justify-content-end">
                        <Edit3
                          size={13}
                          className="action-icon me-1"
                          onClick={() => setNoteToUpdate(item.id)}
                        />
                        <Trash2
                          size={13}
                          className="action-icon text-danger"
                          onClick={() => setNoteToDelete(item.id)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="note-or-memo-item">
                    {item.note.split("\n").map((noteLine, i) => (
                      <React.Fragment key={i}>
                        {!!i && <br />}
                        <span>{noteLine}</span>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
      </div>
      {isMemo ? (
        <div className="mb-2" style={{ flex: 1 }}>
          <div className="mb-2 mt-2">
            <Form.Control
              as="textarea"
              placeholder="Write a memo..."
              rows="9"
              name="formNewStepMemo"
              style={{ height: 192 }}
              value={note}
              onChange={onNoteChange}
            />
          </div>
        </div>
      ) : (
        <Form.Group style={{ flex: 1 }} className="d-flex flex-column">
          <Form.Control
            className="mb-2 mt-2"
            as="textarea"
            placeholder="Write a note..."
            rows="9"
            name="formNewGeneralNotes"
            style={{ width: "99%", margin: "auto", height: 192 }}
            value={note}
            onChange={onNoteChange}
          />
        </Form.Group>
      )}

      <div className="note-or-memo-footer">
        <Button
          size="sm"
          className="note-or-memo-save-button"
          onClick={() => handleNoteSave(note)}
          disabled={!note}
          variant="secondary"
        >
          Submit {isMemo ? "memo" : "note"}
        </Button>
      </div>
    </LoadingWrapper>
  );
};

export default NotesAndMemos;
