export async function loadJewishCalendarEvents(year = "now") {
    const response = await fetch(`https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=on&nx=on&year=${year}&month=x&ss=on&c=off&M=on&s=off&leyning=off`);
    const json = await response.json();
    return json;
}

export function loadLegalCalendarEvents() {
    const holidays = [
        { "title": "New Year's Day", "date": "2024-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2024-01-15" },
        { "title": "Washington's Birthday", "date": "2024-02-19" },
        { "title": "Memorial Day", "date": "2024-05-27" },
        { "title": "Juneteenth", "date": "2024-06-19" },
        { "title": "Independence Day", "date": "2024-07-04" },
        { "title": "Labor Day", "date": "2024-09-02" },
        { "title": "Columbus Day", "date": "2024-10-14" },
        { "title": "Veterans Day", "date": "2024-11-11" },
        { "title": "Thanksgiving Day", "date": "2024-11-28" },
        { "title": "Christmas Day", "date": "2024-12-25" },
        { "title": "New Year's Day", "date": "2025-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2025-01-20" },
        { "title": "Washington's Birthday", "date": "2025-02-17" },
        { "title": "Memorial Day", "date": "2025-05-26" },
        { "title": "Juneteenth", "date": "2025-06-19" },
        { "title": "Independence Day", "date": "2025-07-04" },
        { "title": "Labor Day", "date": "2025-09-01" },
        { "title": "Columbus Day", "date": "2025-10-13" },
        { "title": "Veterans Day", "date": "2025-11-11" },
        { "title": "Thanksgiving Day", "date": "2025-11-27" },
        { "title": "Christmas Day", "date": "2025-12-25" },
        { "title": "New Year's Day", "date": "2026-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2026-01-19" },
        { "title": "Washington's Birthday", "date": "2026-02-16" },
        { "title": "Memorial Day", "date": "2026-05-25" },
        { "title": "Juneteenth", "date": "2026-06-19" },
        { "title": "Independence Day (observed)", "date": "2026-07-03" },
        { "title": "Labor Day", "date": "2026-09-07" },
        { "title": "Columbus Day", "date": "2026-10-12" },
        { "title": "Veterans Day", "date": "2026-11-11" },
        { "title": "Thanksgiving Day", "date": "2026-11-26" },
        { "title": "Christmas Day", "date": "2026-12-25" },
        { "title": "New Year's Day", "date": "2027-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2027-01-18" },
        { "title": "Washington's Birthday", "date": "2027-02-15" },
        { "title": "Memorial Day", "date": "2027-05-31" },
        { "title": "Juneteenth (observed)", "date": "2027-06-18" },
        { "title": "Independence Day (observed)", "date": "2027-07-05" },
        { "title": "Labor Day", "date": "2027-09-06" },
        { "title": "Columbus Day", "date": "2027-10-11" },
        { "title": "Veterans Day", "date": "2027-11-11" },
        { "title": "Thanksgiving Day", "date": "2027-11-25" },
        { "title": "Christmas Day (observed)", "date": "2027-12-24" },
        { "title": "New Year's Day (observed)", "date": "2027-12-31" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2028-01-17" },
        { "title": "Washington's Birthday", "date": "2028-02-21" },
        { "title": "Memorial Day", "date": "2028-05-29" },
        { "title": "Juneteenth", "date": "2028-06-19" },
        { "title": "Independence Day", "date": "2028-07-04" },
        { "title": "Labor Day", "date": "2028-09-04" },
        { "title": "Columbus Day", "date": "2028-10-09" },
        { "title": "Veterans Day (observed)", "date": "2028-11-10" },
        { "title": "Thanksgiving Day", "date": "2028-11-23" },
        { "title": "Christmas Day", "date": "2028-12-25" },
        { "title": "New Year's Day", "date": "2029-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2029-01-15" },
        { "title": "Washington's Birthday", "date": "2029-02-19" },
        { "title": "Memorial Day", "date": "2029-05-28" },
        { "title": "Juneteenth", "date": "2029-06-19" },
        { "title": "Independence Day", "date": "2029-07-04" },
        { "title": "Labor Day", "date": "2029-09-03" },
        { "title": "Columbus Day", "date": "2029-10-08" },
        { "title": "Veterans Day (observed)", "date": "2029-11-12" },
        { "title": "Thanksgiving Day", "date": "2029-11-22" },
        { "title": "Christmas Day", "date": "2029-12-25" },
        { "title": "New Year's Day", "date": "2030-01-01" },
        { "title": "Birthday of Martin Luther King, Jr.", "date": "2030-01-21" },
        { "title": "Washington's Birthday", "date": "2030-02-18" },
        { "title": "Memorial Day", "date": "2030-05-27" },
        { "title": "Juneteenth", "date": "2030-06-19" },
        { "title": "Independence Day", "date": "2030-07-04" },
        { "title": "Labor Day", "date": "2030-09-02" },
        { "title": "Columbus Day", "date": "2030-10-14" },
        { "title": "Veterans Day", "date": "2030-11-11" },
        { "title": "Thanksgiving Day", "date": "2030-11-28" },
        { "title": "Christmas Day", "date": "2030-12-25" }
    ]

    return holidays;
}

